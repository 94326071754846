import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './MangTeam.css';

import { Link } from 'react-router-dom';
import { apiUrl } from '../../../../config/config';

const MangTeam = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/management-team`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const teamMembers = data.data;

  return (
    <section className='bg-[#F5F5FE] py-16'>
      <div className='container p-6 mx-auto'>
        <div className='section-header'>
          <h2>
            আমাদের
            <span className='title-gradient'> ম্যানেজমেন্ট টীম</span>
          </h2>
          <p>আইটেসারেক্ট টেকনোলিজস ম্যানেজমেন্ট টীম</p>
        </div>

        <div className='-mx-4 flex flex-wrap justify-center'>
          {teamMembers?.map((team) => (
            <div
              key={team?.id}
              className='w-full px-4 sm:w-1/2 lg:w-1/4 xl:w-1/4'
            >
              <div className='group mb-8 rounded-xl bg-white px-5 pb-10 pt-12 shadow-testimonial dark:bg-dark dark:shadow-none'>
                <div className='relative z-10 mx-auto mb-5 h-[130px] w-[130px]'>
                  <img
                    src={`${apiUrl}/` + team?.image}
                    alt={team?.name}
                    loading='lazy'
                    className='h-[130px] w-[130px] rounded-full object-cover'
                  />
                  <span className='absolute bottom-0 left-0 -z-10 h-10 w-10 rounded-full bg-secondary opacity-100 transition-all group-hover:opacity-100'></span>
                  <span className='absolute right-0 top-0 -z-10 opacity-100 transition-all group-hover:opacity-100'>
                    <svg
                      width='55'
                      height='53'
                      viewBox='0 0 55 53'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M12.5118 3.1009C13.3681 3.1009 14.0622 2.40674 14.0622 1.55045C14.0622 0.69416 13.3681 0 12.5118 0C11.6555 0 10.9613 0.69416 10.9613 1.55045C10.9613 2.40674 11.6555 3.1009 12.5118 3.1009Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M22.5038 3.1009C23.3601 3.1009 24.0543 2.40674 24.0543 1.55045C24.0543 0.69416 23.3601 0 22.5038 0C21.6475 0 20.9534 0.69416 20.9534 1.55045C20.9534 2.40674 21.6475 3.1009 22.5038 3.1009Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M32.4958 3.1009C33.3521 3.1009 34.0463 2.40674 34.0463 1.55045C34.0463 0.69416 33.3521 0 32.4958 0C31.6395 0 30.9454 0.69416 30.9454 1.55045C30.9454 2.40674 31.6395 3.1009 32.4958 3.1009Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M42.4875 3.1009C43.3438 3.1009 44.038 2.40674 44.038 1.55045C44.038 0.69416 43.3438 0 42.4875 0C41.6312 0 40.9371 0.69416 40.9371 1.55045C40.9371 2.40674 41.6312 3.1009 42.4875 3.1009Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M52.4795 3.1009C53.3358 3.1009 54.03 2.40674 54.03 1.55045C54.03 0.69416 53.3358 0 52.4795 0C51.6233 0 50.9291 0.69416 50.9291 1.55045C50.9291 2.40674 51.6233 3.1009 52.4795 3.1009Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M2.52045 13.0804C3.37674 13.0804 4.0709 12.3862 4.0709 11.5299C4.0709 10.6737 3.37674 9.97949 2.52045 9.97949C1.66416 9.97949 0.970001 10.6737 0.970001 11.5299C0.970001 12.3862 1.66416 13.0804 2.52045 13.0804Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M12.5118 13.0804C13.3681 13.0804 14.0622 12.3862 14.0622 11.5299C14.0622 10.6737 13.3681 9.97949 12.5118 9.97949C11.6555 9.97949 10.9613 10.6737 10.9613 11.5299C10.9613 12.3862 11.6555 13.0804 12.5118 13.0804Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M22.5038 13.0804C23.3601 13.0804 24.0543 12.3862 24.0543 11.5299C24.0543 10.6737 23.3601 9.97949 22.5038 9.97949C21.6475 9.97949 20.9534 10.6737 20.9534 11.5299C20.9534 12.3862 21.6475 13.0804 22.5038 13.0804Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M32.4958 13.0804C33.3521 13.0804 34.0463 12.3862 34.0463 11.5299C34.0463 10.6737 33.3521 9.97949 32.4958 9.97949C31.6395 9.97949 30.9454 10.6737 30.9454 11.5299C30.9454 12.3862 31.6395 13.0804 32.4958 13.0804Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M42.4875 13.0804C43.3438 13.0804 44.038 12.3862 44.038 11.5299C44.038 10.6737 43.3438 9.97949 42.4875 9.97949C41.6312 9.97949 40.9371 10.6737 40.9371 11.5299C40.9371 12.3862 41.6312 13.0804 42.4875 13.0804Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M52.4795 13.0804C53.3358 13.0804 54.03 12.3862 54.03 11.5299C54.03 10.6737 53.3358 9.97949 52.4795 9.97949C51.6233 9.97949 50.9291 10.6737 50.9291 11.5299C50.9291 12.3862 51.6233 13.0804 52.4795 13.0804Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M2.52045 23.0604C3.37674 23.0604 4.0709 22.3662 4.0709 21.5099C4.0709 20.6536 3.37674 19.9595 2.52045 19.9595C1.66416 19.9595 0.970001 20.6536 0.970001 21.5099C0.970001 22.3662 1.66416 23.0604 2.52045 23.0604Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M12.5118 23.0604C13.3681 23.0604 14.0622 22.3662 14.0622 21.5099C14.0622 20.6536 13.3681 19.9595 12.5118 19.9595C11.6555 19.9595 10.9613 20.6536 10.9613 21.5099C10.9613 22.3662 11.6555 23.0604 12.5118 23.0604Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M22.5038 23.0604C23.3601 23.0604 24.0543 22.3662 24.0543 21.5099C24.0543 20.6536 23.3601 19.9595 22.5038 19.9595C21.6475 19.9595 20.9534 20.6536 20.9534 21.5099C20.9534 22.3662 21.6475 23.0604 22.5038 23.0604Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M32.4958 23.0604C33.3521 23.0604 34.0463 22.3662 34.0463 21.5099C34.0463 20.6536 33.3521 19.9595 32.4958 19.9595C31.6395 19.9595 30.9454 20.6536 30.9454 21.5099C30.9454 22.3662 31.6395 23.0604 32.4958 23.0604Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M42.4875 23.0604C43.3438 23.0604 44.038 22.3662 44.038 21.5099C44.038 20.6536 43.3438 19.9595 42.4875 19.9595C41.6312 19.9595 40.9371 20.6536 40.9371 21.5099C40.9371 22.3662 41.6312 23.0604 42.4875 23.0604Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M52.4795 23.0604C53.3358 23.0604 54.03 22.3662 54.03 21.5099C54.03 20.6536 53.3358 19.9595 52.4795 19.9595C51.6233 19.9595 50.9291 20.6536 50.9291 21.5099C50.9291 22.3662 51.6233 23.0604 52.4795 23.0604Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M2.52045 33.0404C3.37674 33.0404 4.0709 32.3462 4.0709 31.4899C4.0709 30.6336 3.37674 29.9395 2.52045 29.9395C1.66416 29.9395 0.970001 30.6336 0.970001 31.4899C0.970001 32.3462 1.66416 33.0404 2.52045 33.0404Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M12.5118 33.0404C13.3681 33.0404 14.0622 32.3462 14.0622 31.4899C14.0622 30.6336 13.3681 29.9395 12.5118 29.9395C11.6555 29.9395 10.9613 30.6336 10.9613 31.4899C10.9613 32.3462 11.6555 33.0404 12.5118 33.0404Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M22.5038 33.0404C23.3601 33.0404 24.0543 32.3462 24.0543 31.4899C24.0543 30.6336 23.3601 29.9395 22.5038 29.9395C21.6475 29.9395 20.9534 30.6336 20.9534 31.4899C20.9534 32.3462 21.6475 33.0404 22.5038 33.0404Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M32.4958 33.0404C33.3521 33.0404 34.0463 32.3462 34.0463 31.4899C34.0463 30.6336 33.3521 29.9395 32.4958 29.9395C31.6395 29.9395 30.9454 30.6336 30.9454 31.4899C30.9454 32.3462 31.6395 33.0404 32.4958 33.0404Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M42.4875 33.0404C43.3438 33.0404 44.038 32.3462 44.038 31.4899C44.038 30.6336 43.3438 29.9395 42.4875 29.9395C41.6312 29.9395 40.9371 30.6336 40.9371 31.4899C40.9371 32.3462 41.6312 33.0404 42.4875 33.0404Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M52.4795 33.0404C53.3358 33.0404 54.03 32.3462 54.03 31.4899C54.03 30.6336 53.3358 29.9395 52.4795 29.9395C51.6233 29.9395 50.9291 30.6336 50.9291 31.4899C50.9291 32.3462 51.6233 33.0404 52.4795 33.0404Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M2.52045 43.0203C3.37674 43.0203 4.0709 42.3262 4.0709 41.4699C4.0709 40.6136 3.37674 39.9194 2.52045 39.9194C1.66416 39.9194 0.970001 40.6136 0.970001 41.4699C0.970001 42.3262 1.66416 43.0203 2.52045 43.0203Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M12.5118 43.0203C13.3681 43.0203 14.0622 42.3262 14.0622 41.4699C14.0622 40.6136 13.3681 39.9194 12.5118 39.9194C11.6555 39.9194 10.9613 40.6136 10.9613 41.4699C10.9613 42.3262 11.6555 43.0203 12.5118 43.0203Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M22.5038 43.0203C23.3601 43.0203 24.0543 42.3262 24.0543 41.4699C24.0543 40.6136 23.3601 39.9194 22.5038 39.9194C21.6475 39.9194 20.9534 40.6136 20.9534 41.4699C20.9534 42.3262 21.6475 43.0203 22.5038 43.0203Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M32.4958 43.0203C33.3521 43.0203 34.0463 42.3262 34.0463 41.4699C34.0463 40.6136 33.3521 39.9194 32.4958 39.9194C31.6395 39.9194 30.9454 40.6136 30.9454 41.4699C30.9454 42.3262 31.6395 43.0203 32.4958 43.0203Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M42.4875 43.0203C43.3438 43.0203 44.038 42.3262 44.038 41.4699C44.038 40.6136 43.3438 39.9194 42.4875 39.9194C41.6312 39.9194 40.9371 40.6136 40.9371 41.4699C40.9371 42.3262 41.6312 43.0203 42.4875 43.0203Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M52.4795 43.0203C53.3358 43.0203 54.03 42.3262 54.03 41.4699C54.03 40.6136 53.3358 39.9194 52.4795 39.9194C51.6233 39.9194 50.9291 40.6136 50.9291 41.4699C50.9291 42.3262 51.6233 43.0203 52.4795 43.0203Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M2.52045 53.0001C3.37674 53.0001 4.0709 52.3059 4.0709 51.4496C4.0709 50.5933 3.37674 49.8992 2.52045 49.8992C1.66416 49.8992 0.970001 50.5933 0.970001 51.4496C0.970001 52.3059 1.66416 53.0001 2.52045 53.0001Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M12.5118 53.0001C13.3681 53.0001 14.0622 52.3059 14.0622 51.4496C14.0622 50.5933 13.3681 49.8992 12.5118 49.8992C11.6555 49.8992 10.9613 50.5933 10.9613 51.4496C10.9613 52.3059 11.6555 53.0001 12.5118 53.0001Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M22.5038 53.0001C23.3601 53.0001 24.0543 52.3059 24.0543 51.4496C24.0543 50.5933 23.3601 49.8992 22.5038 49.8992C21.6475 49.8992 20.9534 50.5933 20.9534 51.4496C20.9534 52.3059 21.6475 53.0001 22.5038 53.0001Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M32.4958 53.0001C33.3521 53.0001 34.0463 52.3059 34.0463 51.4496C34.0463 50.5933 33.3521 49.8992 32.4958 49.8992C31.6395 49.8992 30.9454 50.5933 30.9454 51.4496C30.9454 52.3059 31.6395 53.0001 32.4958 53.0001Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M42.4875 53.0001C43.3438 53.0001 44.038 52.3059 44.038 51.4496C44.038 50.5933 43.3438 49.8992 42.4875 49.8992C41.6312 49.8992 40.9371 50.5933 40.9371 51.4496C40.9371 52.3059 41.6312 53.0001 42.4875 53.0001Z'
                        fill='#3758F9'
                      ></path>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M52.4795 53.0001C53.3358 53.0001 54.03 52.3059 54.03 51.4496C54.03 50.5933 53.3358 49.8992 52.4795 49.8992C51.6233 49.8992 50.9291 50.5933 50.9291 51.4496C50.9291 52.3059 51.6233 53.0001 52.4795 53.0001Z'
                        fill='#3758F9'
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className='text-center'>
                  <Link to={`/team/${team?.slug}`}>
                    <h1 className='text-[17px] font-bold hover:text-[#1bb57b] cursor-pointer'>
                      {team?.name}
                    </h1>
                  </Link>
                  <p className='text-gray-400 mb-5'>{team?.designation}</p>

                  <div className='flex items-center justify-center gap-3'>
                    <a
                      target='_blank'
                      href={team?.facebook}
                      className='text-[#33354d] border border-[#d4d7e5] rounded-md flex justify-center items-center w-9 h-9 hover:text-white hover:bg-[#1877f2] hover:border-[#1877f2] hover:shadow-[0_0.375rem_1.125rem_-0.375rem_rgba(24,119,242,0.8)] cursor-pointer'
                    >
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='fill-current'
                      >
                        <path
                          d='M13.3315 7.25625H11.7565H11.194V6.69375V4.95V4.3875H11.7565H12.9377C13.2471 4.3875 13.5002 4.1625 13.5002 3.825V0.84375C13.5002 0.534375 13.2752 0.28125 12.9377 0.28125H10.8846C8.66272 0.28125 7.11584 1.85625 7.11584 4.19062V6.6375V7.2H6.55334H4.64084C4.24709 7.2 3.88147 7.50937 3.88147 7.95937V9.98438C3.88147 10.3781 4.19084 10.7438 4.64084 10.7438H6.49709H7.05959V11.3063V16.9594C7.05959 17.3531 7.36897 17.7188 7.81897 17.7188H10.4627C10.6315 17.7188 10.7721 17.6344 10.8846 17.5219C10.9971 17.4094 11.0815 17.2125 11.0815 17.0437V11.3344V10.7719H11.6721H12.9377C13.3033 10.7719 13.5846 10.5469 13.6408 10.2094V10.1813V10.1531L14.0346 8.2125C14.0627 8.01562 14.0346 7.79063 13.8658 7.56562C13.8096 7.425 13.5565 7.28437 13.3315 7.25625Z'
                          fill=''
                        ></path>
                      </svg>
                    </a>
                    <a
                      target='_blank'
                      href={team?.twitter}
                      className='text-[#33354d] border border-[#d4d7e5] rounded-md flex justify-center items-center w-9 h-9 hover:text-white hover:bg-[#1da1f2] hover:border-[#1da1f2] hover:shadow-[0_0.375rem_1.125rem_-0.375rem_rgba(29,161,242,0.8)] cursor-pointer'
                    >
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='fill-current'
                      >
                        <path
                          d='M16.4647 4.83752C16.565 4.72065 16.4343 4.56793 16.2859 4.62263C15.9549 4.74474 15.6523 4.82528 15.2049 4.875C15.7552 4.56855 16.0112 4.13054 16.2194 3.59407C16.2696 3.46467 16.1182 3.34725 15.9877 3.40907C15.458 3.66023 14.8864 3.84658 14.2854 3.95668C13.6913 3.3679 12.8445 3 11.9077 3C10.1089 3 8.65027 4.35658 8.65027 6.02938C8.65027 6.26686 8.67937 6.49818 8.73427 6.71966C6.14854 6.59919 3.84286 5.49307 2.24098 3.79696C2.13119 3.68071 1.93197 3.69614 1.86361 3.83792C1.68124 4.21619 1.57957 4.63582 1.57957 5.07762C1.57957 6.12843 2.15446 7.05557 3.02837 7.59885C2.63653 7.58707 2.2618 7.51073 1.91647 7.38116C1.74834 7.31808 1.5556 7.42893 1.57819 7.59847C1.75162 8.9004 2.80568 9.97447 4.16624 10.2283C3.89302 10.2978 3.60524 10.3347 3.30754 10.3347C3.23536 10.3347 3.16381 10.3324 3.0929 10.3281C2.91247 10.3169 2.76583 10.4783 2.84319 10.6328C3.35357 11.6514 4.45563 12.3625 5.73809 12.3847C4.62337 13.1974 3.21889 13.6816 1.69269 13.6816C1.50451 13.6816 1.42378 13.9235 1.59073 14.0056C2.88015 14.6394 4.34854 15 5.90878 15C11.9005 15 15.1765 10.384 15.1765 6.38067C15.1765 6.24963 15.1732 6.11858 15.1672 5.98877C15.6535 5.66205 16.0907 5.27354 16.4647 4.83752Z'
                          fill=''
                        ></path>
                      </svg>
                    </a>
                    <a
                      target='_blank'
                      href={team?.linkedin}
                      className='text-[#33354d] border border-[#d4d7e5] rounded-md flex justify-center items-center w-9 h-9 hover:text-white hover:bg-[#0077b5] hover:border-[#0077b5] hover:shadow-[0_0.375rem_1.125rem_-0.375rem_rgba(0,119,181,0.8)] cursor-pointer'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        fill='currentColor'
                        className='bi bi-linkedin'
                        viewBox='0 0 16 16'
                      >
                        <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z' />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MangTeam;
